@import "https://fonts.googleapis.com/css?family=Fira+Code";

// fontawesome
@import "./fontawesome.custom";

// @import "../node_modules/bootstrap/scss/bootstrap";
@import "./bootstrap.custom";

// animate.css
@import "./animate.custom";

// other CSS
footer a {
    text-decoration: none;
}